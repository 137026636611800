.single_workplace_list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid black;
}
.single_workplace_list a i {
  color: red;
}
.single_workplace_list_data {
  display: flex;
  gap: 10px 10px;
  align-items: center;
}

.single_workplace_list_data i {
  font-size: 22px;
  color: #b7b7b7;
}
.workplace_data {
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
}
.workplace_data h4 {
  font-size: 14px;
  color: #232323;
}
.workplace_data p {
  color: #a4a4a4;
  font-size: 14px;
  margin-bottom: 0 !important;
}
.workplace_data span {
  color: #00bfa6;
  font-size: 14px;
}
.add_workplace_btn a {
  color: #ffffff;
  background-color: #ffbc00;
  border-color: #ffbc00;
  border: 1px solid transparent;
  padding: 6px 0px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  line-height: 16px;
  width: 100% !important;
  text-align: center;
  height: 56px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: var(--borderRadius);
  margin-bottom: 25px;
}

.workplace-list {
  height: 66vh !important;
  /* overflow-y: auto; */
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.deeplink-work button {
  width: 40%;
  margin-left: 10px;
}
.deeplink-work button a {
  color: white;
}

/*RESPONSIVE*/

/*Tablet*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*mobile rotation*/
@media (max-width: 767px) {
}

/*mobile*/
@media (max-width: 540px) {
}

@media (max-width: 325px) {
}
