.congratulation_form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px 0px;
}
.congratulation_form figure {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-bottom: 15px;
}
.congratulation_form figure img {
  width: 60%;
}
.congratulation_form h3 {
  display: flex;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  font-size: 16px;
  font-style: normal;
  color: #1e5951;
  justify-content: center;
  font-weight: 500;
}
.congratulation_form span {
  display: flex;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  color: #232323;
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 15px;
  justify-content: center;
}
.congratulation_form button {
  color: #ffffff;
  background-color: #ffbc00;
  border-color: #ffbc00;
  border: 1px solid transparent;
  padding: 6px 0px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  height: 56px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: var(--borderRadius);
}
