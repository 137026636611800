.icon {
  padding: 6px 10px;
  border-radius: 50%;
  margin-right: 10px;
  /* width: 50px;
  height: 35px; */
}
.zele {
  background-color: purple;
}
.copy-profile {
  font-size: 0.7rem;
  padding: 10px 5px;
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
  margin-right: 10px;
  width: 120px;
}
.close-btn {
  background-color: rgb(255, 213, 135);
  border: none;
  color: white;
  padding: 2px 15px;
  border-radius: 8px;
  margin-top: 0px !important;
}
.copy-area {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}
.copy-icon {
  margin-left: 5px;
  font-size: 1.3rem;
  padding: 3px;
  color: orange;
  /* transform: scaleX(-1); */
  cursor: pointer;
}
.chime-text {
  color: white;
  font-size: 1.3rem;
  background-color: green;
  padding: 0 8px;
  border-radius: 50%;
  margin-right: 10px;
}

.modal-buttons button {
  margin: auto auto auto 20px;
  padding: 5px 20px;
}
