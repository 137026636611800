.job_container {
  max-width: 500px;
  margin: 0px auto;
  height: 100%;
}
.login_section .greate_what_job_form {
  float: left;
  width: 100%;
  padding: 0px 0px;
}
.greate_what_job_form h3 {
  margin-bottom: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  color: #232323;
  font-weight: 600;
}
.greate_what_job_form span {
  margin-bottom: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  color: #232323;
  font-weight: 500;
}

.login_section .greate_what_job_form form .greate_what_job_data_fields {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px 0px;
}

.login_section
  .greate_what_job_form
  form
  .greate_what_job_data_fields
  .input_field {
  width: 100%;
}
.login_section
  .greate_what_job_form
  form
  .greate_what_job_data_fields
  .input_field
  label {
  width: 100%;
  display: block;
  font-size: 14px;
  color: #232323;
  padding-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 16px;
  text-align: left;
}
.input_phone_field {
  display: flex;
  gap: 0px 5px;
}
.input_phone_field select {
  border: 2px solid #d8d8d8;
  outline: none;
  padding: 10px 10px;
}
.input_phone_field input {
  border: 2px solid #d8d8d8;
  outline: none;
  padding: 10px 10px;
  width: 100%;
}

.login_section
  .greate_what_job_form
  form
  .greate_what_job_data_fields
  .submit_btn
  button {
  color: #ffffff;
  background-color: #ffbc00;
  border-color: #ffbc00;
  border: 1px solid transparent;
  padding: 6px 0px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  height: 56px;
  font-weight: 900;
  border-radius: 7px;
  margin-bottom: 5px;
}
.login_section
  .greate_what_job_form
  form
  .greate_what_job_data_fields
  .submit_btn
  a {
  color: #ffffff;
  background-color: #ffbc00;
  border-color: #ffbc00;
  border: 1px solid transparent;
  padding: 6px 0px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  line-height: 16px;
  width: 100% !important;
  text-align: center;
  height: 56px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.greate_what_job_checkbox_field {
  float: left;
  width: 100%;
}
.greate_what_job_checkbox_field label {
  cursor: pointer;
  display: inline-block;
  padding: 2px;
  padding-bottom: 8px;
  margin-bottom: 0;
}

.greate_what_job_checkbox input[type="checkbox"] {
  display: none;
}
.greate_what_job_checkbox label span {
  background-color: #dfdfdf;
  padding: 10px 20px;
  border-radius: 50px;
  margin: 0px;
  font-size: 14px;
  color: #232323;
  display: inline-block;
  text-transform: capitalize !important;
}
.greate_what_job_checkbox input[type="checkbox"]:checked ~ span {
  background-color: #ffbc00;
  color: #fff;
}

/*RESPONSIVE*/

/*Tablet*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*mobile rotation*/
@media (max-width: 767px) {
}

/*mobile*/
@media (max-width: 540px) {
}

@media (max-width: 325px) {
}
