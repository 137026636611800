figure {
  margin: 0px;
}
.admin-icon {
  height: 16px;
  width: 16px;
}

.side_bar_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #059682;
  padding: 30px 10px;
  flex-direction: column;
  gap: 10px;
}
.side_bar_logo img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.side_bar_logo h3 {
  margin: 0px;
  color: #fff;
}
.side_bar_logo span {
  color: #fff;
}

.side_bar {
  height: 100vh;
  width: 20%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background: #f3f2f2;
  transition: 0.5s;
  transform: translateX(-100%);
}

.side_bar.show-sidebar {
  transform: translateX(0%);
}

.side_bar .closebtn {
  position: absolute;
  top: 15px;
  left: 25px;
  font-size: 25px;
  color: #fff;
  cursor: pointer;
}

.dashboard_buttons {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 30px 0px;
  color: #232323;
  padding-left: 20px;
  padding-top: 20px;
  background-color: #f3f2f2;
}
.dashboard_buttons a {
  display: flex;
  gap: 0px 10px;
  align-items: center;
}
.dashboard_buttons a {
  text-decoration: none;
  color: black;
}
.dashboard_buttons .btn {
  font-size: 18px;
  margin-bottom: 15px;
  cursor: pointer;
}

.dashboard_buttons .active {
  border-left: 2px solid #232323;
  padding-left: 5px;
}
@media screen and (max-width: 850px) {
  .side_bar {
    width: 25%;
  }
}
@media screen and (max-width: 650px) {
  .side_bar {
    width: 39%;
  }
}
@media screen and (max-width: 500px) {
  .side_bar {
    width: 57%;
  }
}
@media screen and (max-width: 350px) {
  .side_bar {
    width: 73%;
  }
}
