figure {
  margin: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  background-color: #00bfa6;
  height: 215px;
}

.added_payment_list {
  display: flex;
  flex-direction: column;
  gap: 10px 0px;
  margin-top: 15px;
  margin-bottom: 40px;
}
.single_payment_list {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;

  padding: 10px 0px;
}
.single_payment_list i {
  color: red;
}
.single_payment_list_data {
  display: flex;
  gap: 10px 10px;
  /* align-items: center; */
}

.single_payment_list .single_payment_list_data figure img {
  width: 34px;
}
.single_payment_list .single_payment_list_data input {
  /* border: 2px solid #d8d8d8; */
  border-radius: 10px;
  outline: none;
  padding: 10px 10px;
}
.single_payment_list .single_payment_list_data input::placeholder {
  font-style: italic;
}
.single_payment_list a {
  color: red;
  text-decoration: none;
  cursor: pointer;
}

.payment_methods {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". . . . .";
  width: 100%;
  gap: 15px 12px;
  margin-bottom: 20px;
}
.payment_methods a img {
  width: 60px;
  height: 60px;
}

.add_new_payment {
  display: flex;
  flex-direction: column;
  gap: 10px 0px;
}

.add_payment_method_btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}
.add_payment_method_btn button {
  color: #ffffff;
  background-color: #ffbc00;
  border-color: #ffbc00;
  border: 1px solid transparent;
  padding: 6px 0px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  line-height: 16px;
  width: 22%;
  text-align: center;
  height: 44px;
  font-weight: 900;
  border-radius: var(--borderRadius);
}

.payment_conditions {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 15px 0px;
  text-align: center;
}
.payment_conditions span {
  font-size: 14px;
  color: #232323;
}
.payment_conditions h3 {
  font-size: 16px;
  color: #232323;
  font-weight: normal;
}
.payment_conditions h3 a {
  color: #232323;
  text-decoration: underline;
}
.pay-icon {
  position: relative;
  top: 15px;
  font-size: 3rem;
}
.payment_form {
  height: 70vh !important;
  overflow-y: auto;
}
.payment_methods a {
  display: flex;
  justify-content: center;
  align-items: center;
}
/*RESPONSIVE*/

/*Tablet*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*mobile rotation*/
@media (max-width: 400px) {
  .payment_methods {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . .";
    gap: 15px 12px;
  }
}

/*mobile*/
@media (max-width: 540px) {
}

@media (max-width: 325px) {
}
