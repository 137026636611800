* {
  -web-kit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: "Roboto", sans-serif;
}
:root {
  --borderRadius: 7px;
}
html {
  scroll-behavior: smooth;
}
body {
  float: left;
  width: 100%;
  margin: 0px;
}
.main-body {
  float: left;
  top: 115px;
  width: 100%;
  margin: 0px;
  position: relative;
}
.login_container {
  max-width: 430px;
  margin: 0px auto;
  height: 100%;
}

.login_section {
  float: left;
  width: 100%;
  padding: 0px 10px;
  height: 100%;
}
.login_box {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.login_section .login_form {
  float: left;
  width: 100%;
  padding: 0px 0px;
}
.login_form h5 {
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 80%;
  font-size: 16px;
  font-style: normal;
  color: #232323;
  font-weight: 500;
}
.login_section .login_form .company_logo {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login_section .login_form .company_logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 42px;
  font-style: normal;
  color: #15dad7;
  font-weight: 500;
  gap: 0px 2px;
}
.login_section .login_form .company_logo a i {
  font-size: 7px;
  color: #ffbc00;
}
.login_section .login_form form .login_fields {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px 0px;
}

.login_section .login_form form .login_fields .input_field {
  width: 100%;
}
.login_section .login_form form .login_fields .input_field label {
  width: 100%;
  display: block;
  font-size: 14px;
  color: #232323;
  padding-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 16px;
  text-align: left;
}
.input_phone_field {
  display: flex;
  gap: 0px 5px;
}
.input_phone_field select {
  border: 2px solid #d8d8d8;
  outline: none;
  background-color: rgba(206, 199, 199, 0.699);
  padding: 10px 10px;
  border-radius: var(--borderRadius);
}
.input_phone_field input {
  border: 2px solid #d8d8d8;
  background-color: rgba(206, 199, 199, 0.699);
  outline: none;
  padding: 10px 10px;
  width: 100%;
  border-radius: var(--borderRadius);
}
.submit_btn {
  position: relative;
}

.login_section .login_form form .login_fields .submit_btn button {
  position: absolute;
  top: 30px;
  color: #ffffff;
  background-color: #ffbc00;
  border-color: #ffbc00;
  border: 1px solid transparent;
  padding: 6px 0px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  height: 56px;
  font-weight: 900;
  border-radius: var(--borderRadius);
}
.login_section .login_form form .login_fields .submit_btn a {
  color: #ffffff;
  background-color: #ffbc00;
  border-color: #ffbc00;
  border: 1px solid transparent;
  padding: 6px 0px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  line-height: 16px;
  width: 100% !important;
  text-align: center;
  height: 56px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.terms_and_conditions {
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.terms_and_conditions p {
  font-size: 16px;
  color: #232323;
  font-weight: normal;
}
.terms_and_conditions a {
  color: #ffbc00;
  font-size: 16px;
}

.code {
  width: 20% !important;
  padding: 10px 10px 10px 14px !important;
}

.code-container {
  position: relative;
  width: 90px;
}
.code-container input {
  width: 100% !important;
  border-radius: var(--borderRadius);
  background-color: rgba(206, 199, 199, 0.699);
}

.number-container {
  width: 100%;
}

.plus-icon {
  position: absolute;
  top: 19px;
  left: 6px;
  font-size: 10px;
}
/*RESPONSIVE*/

/*Tablet*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*mobile rotation*/
@media (max-width: 767px) {
}

/*mobile*/
@media (max-width: 540px) {
}

@media (max-width: 325px) {
}
