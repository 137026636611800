.skip_btn {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.skip_btn a {
  background-color: #eeeded;
  text-decoration: none;
  border-radius: 50px;
  padding: 10px 20px;
  color: gray;
  font-size: 16px;
  font-weight: normal;
}
.input_figure_field {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.input_figure_field figure {
  height: 140px;
  position: relative;
}
.upload_photo {
  width: 100%;
  text-align: center !important;
}
.input_figure_field figure img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}
.input_figure_field figure .change_pic {
  position: absolute;
  bottom: -12px;
  text-align: center;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  opacity: 1;
}
.change_pic .camera_label {
  padding: 0px !important;
  width: 40px !important;
  height: 40px;
  background: #ffbc00;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.change_pic .camera_label i {
  font-size: 18px;
  color: #fff;
}
.change_pic input[type="file"] {
  display: none;
}
