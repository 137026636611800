/* .bottom-nav {
  width: 35%;
  position: absolute;
  bottom: 0px;
  left: 31%;
} */
.bottom-nav ul {
  border-top: 1px solid gray;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 0;
}
.bottom-nav ul a {
  text-decoration: none;
  color: initial;
}
.bottom-nav ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.bottom-nav ul li i {
  font-size: 1.1rem;
  font-weight: 500;
}
.active-nav {
  position: relative;
}
.active-nav:after {
  position: absolute;
  content: "";
  border: 2px solid #ff9800;
  top: -17px;
  width: 100%;
}
.active-nav i {
  color: white;
  background: orange;
  padding: 6px 8px;
  border-radius: 5px;
}
.active-nav span {
  color: orange;
}
