.jumbotron {
  background-color: #15dad7;
  border-radius: 0;
  color: white;
  padding: 2rem;
}

.btn-warning {
  background-color: #ffbc00;
  border-color: #ffbc00;
  color: white;
}

.btn-warning:hover,
.btn-warning:focus {
  color: white;
}

.secondary-background {
  background-color: #1b6cbe;
  color: white;
}

.secondary-background a {
  color: white;
}

.accent-color {
  background-color: #277acd;
}

.container {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin: 0 !important;
  width: 100% !important;
}

.form-control,
.form-control:focus,
.form-control:hover {
  border-color: white;
  box-shadow: none;
}

.input-group-text {
  background-color: #fff;
}

.input-group-prepend span {
  border-color: white;
}

.pointer-cursor {
  cursor: pointer !important;
}

.search-btn input,
.search-btn span {
  background-color: #fafafa !important;
  border: none;
  border-radius: 0;
}

.badge-yellow {
  color: white;
}

.hero-image {
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 0;
}

.badge {
  margin: 5px 5px 2px 2px;
  border: 0;
  padding: 10px;
  font-weight: normal;
  border-radius: 25px;
}

.card {
  border-left: none;
  border-right: none;
}

.btn-venmo {
  background-color: #86c9ef;
  color: #fff;
}

.btn-cashapp {
  background-color: #43d656;
  color: #fff;
}

.btn-paypal {
  background-color: #04a9f4;
  color: #fff;
}

.btn-zelle {
  background-color: #8f34e1;
  color: #fff;
}

.btn-chime {
  background-color: #31c981;
  color: #fff;
}

.btn-other {
  background-color: #f5f5f5;
  color: #3d7777;
}
.btn-btc {
  background-color: #f7931a;
  color: white;
}
.btn-eth {
  background-color: #aaa195;
  color: white;
}
.btn-lydia {
  background-color: #1080f4;
  color: white;
}
.font-lg {
  font-size: 100% !important;
}

.badge-pill a,
.badge-pill a:hover {
  text-decoration: none;
  color: white;
}

.image-container {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 25%;
  border: 5px solid #fff;
  margin-top: -1.5rem;
}

.employee-img-container {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
}

.landscape-img {
  width: auto;
  height: 100%;
}

.portrait-img {
  max-width: 100%;
  height: auto;
  margin-top: -45%;
}

.landscape-employee-img {
  width: auto;
  height: 100%;
}

.portrait-employee-img {
  max-width: 110%;
  height: auto;
  margin-top: -45%;
}
.bio {
  height: 120px;
  width: 100%;
  padding: 5px;
  border-radius: 8px;
}
.bio:focus {
  border-color: #15dad7;
  outline: #15dad7;
}
.profile-color {
  color: #15dad7;
}
.abc {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.test {
  /* margin-top: auto;
  position: sticky; */
  margin-top: auto;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
}
.employeeList {
  margin-top: 200px;
}
input[type="text"],
input[type="number"],
textarea {
  font-size: 16px;
}

@media (max-width: 1500px) {
  .employee-img-container {
    width: 90px;
    height: 90px;
  }
}

@media (max-width: 1200px) {
  .employee-img-container {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 1024px) {
  .employee-img-container {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 500px) {
  .employee-img-container {
    width: 50px;
    height: 50px;
  }
}
