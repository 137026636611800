.profile-color {
  margin-top: 10px;
  margin-bottom: 5px;
}
.profile-color + input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid;
  height: 33px;
  padding: 5px;
}
.profile-color + input:focus {
  outline: #15dad7;
  border-color: #15dad7;
}
.profile-figure {
  height: 100px !important;
}
.profile-figure img {
  height: 100px !important;
  width: 100px !important;
}
