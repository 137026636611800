.user-form {
  margin: 20px;
}
.user-form input {
  width: 100%;
}
.user-form label {
  margin-bottom: 0px !important;
  margin-top: 10px;
}
.create-user-image {
  display: flex !important;
  align-items: center !important;
  gap: 10px;
  margin-top: 10px;
}
.payment-select {
  display: flex;
  gap: 10px;
}
